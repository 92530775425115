//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BannerForUK',
  props: {
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
}
